import React from "react";

export function Footer() {
  const scrollTop = () => window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
  return <div className="p-7 h-full block footer-color">
    <div className="flex flex-col items-start sm:items-center gap-5 py-8">
      <div className="container mx-auto text-center">
        <p>&copy; 2024 Tootmon. All rights reserved.</p>
      </div>
    </div>
  </div>;
}

import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "app/config/store";
import {Button} from "@mui/material";
import {SensorConfigEditModal} from "app/pages/production-rooms/sensor-config-edit-modal";
import {getAllSensorConfigsByDevice, saveSensorConfig} from "app/reducers/sensor-config.reducer";
import {ConfirmModal} from "app/pages/production-rooms/confirm-modal";
import axios from "axios";
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import {DatePicker} from "@mui/x-date-pickers";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {Else, If, Then, When} from "react-if";
import {hasAnyAuthority} from "app/shared/auth/private-route";
import {AUTHORITIES} from "app/config/constants";
import {useTranslation} from "react-i18next";
import dayjs from "dayjs";
import {toast} from "react-toastify";
import { Line } from 'react-chartjs-2';

interface DataChartParams {
  options: any;
  sensorConfig: any;
  deviceId: any;
}

export function DataChart({options, sensorConfig, deviceId}: DataChartParams) {
  const {t, i18n} = useTranslation();
  const oneDateTime = (24 * 60 * 60 * 1000); //1 day

  const sensorConfigId = sensorConfig?.id;
  const dispatch = useAppDispatch();
  const [sensorConfigDefaultValues, setSensorConfigDefaultValues] = useState(null);

  const [sensorData, setSensorData] = useState([]);
  const [sensorDataLoading, setSensorDataLoading] = useState(true);
  const time = new Date();
  time.setTime(time.getTime() - oneDateTime);
  time.setHours(0, 0, 0, 0);
  time.setFullYear(2023);

  const [startTime, setStartTime] = useState(time);
  const end = new Date();
  end.setHours(23, 59, 59, 999);
  end.setFullYear(2024);
  const [endTime, setEndTime] = useState(end);

  const [data2, setData2] = useState({
    datasets: [
      {
        label: 'Dataset 1',
        data: [],
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
    ],
  });

  const options2 = {
        scales: {
            x: {
                type: 'time' as const
            }
        }
  };
  

  useEffect(() => {
    setSensorDataLoading(true);
    axios.get<any>('api/sensor-data/config/' + sensorConfigId + '?startTime=' + startTime.toISOString() + '&endTime=' + endTime.toISOString())
      .then((response) => {
        setSensorData(response.data);
        setSensorDataLoading(false);
      }, (error) => {
        setSensorDataLoading(false);
        setSensorData([]);
      });
  }, [sensorConfig?.id, startTime, endTime]);

  const [finalData, setFinalData] = useState([]);
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  const [graphConfig, setGraphConfig] = useState(options);

  useEffect(() => {
    const fields = sensorConfig?.fields.split(',');
    let result = sensorData?.map((x) => {
      const data = [new Date(x?.createdDate)];
      try {
        const payload = JSON.parse(x?.payload);
        fields.forEach((field) => {
          data.push(payload[field]);
        });
      } catch (err) {
        return;
      }
      return data;
    });

    if (sensorConfig.staticFields) {
      const staticFields = JSON.parse(sensorConfig.staticFields);
      const result2 = result.map((x) => {
        const copy = [...x];
        Object.keys(staticFields).forEach((key) => {
          copy.push(staticFields[key]);
        });
        return copy;
      });

      setFinalData([["time", ...fields, ...Object.keys(staticFields)], ...result2]);
    } else {
      setFinalData([["time", ...fields], ...result]);
    }
  }, [sensorData]);

  useEffect(() => {
    const dateTicks = [startTime];
    const dateTicksOptions = {
      hAxis: {
        ticks: dateTicks,
      },
    };

    if (sensorConfig.graphConfig) {
      try {
        let x = JSON.parse(sensorConfig.graphConfig);
        setGraphConfig({...options, ...x, hAxis: {...options.hAxis, ...x.hAxis, ...dateTicksOptions.hAxis}});
      } catch (err) {
        setGraphConfig({...options, hAxis: {...options.hAxis, ...dateTicksOptions.hAxis}});
      }
    } else {
      setGraphConfig({...options, hAxis: {...options.hAxis, ...dateTicksOptions.hAxis}});
    }
  }, [sensorData]);

  useEffect(() => {
    const mappedData = sensorData.map(item => ({
      x: new Date(item.createdDate),
      y: JSON.parse(item.payload)[sensorConfig.fields.split(',')[0]], // Adjust as needed
    }));
    setData2({
      datasets: [
        {
          label: 'Dataset 1',
          data: mappedData,
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
      ],
    });
  }, [sensorData, sensorConfig]);

  return (
    <div className="w-full m-auto mt-12">
      <div className="flex flex-col lg:flex-row justify-between items-center gap-4 mt-5 mb-3">
        <div className="flex flex-col sm:flex-row gap-4 items-center">
          <div className="flex flex-row gap-4 items-center">
            <ArrowBackIosIcon className="cursor-pointer" onClick={() => setStartTime(new Date(startTime.getTime() - oneDateTime))}></ArrowBackIosIcon>
            <DatePicker key={"startTime" + startTime.getTime()} onChange={(e) => setStartTime(e.toDate())} format="DD.MM.YYYY" defaultValue={dayjs(startTime)} slotProps={{textField: {size: 'small'}}} formatDensity="dense" label={t("label.start")}/>
            <ArrowForwardIosIcon className="cursor-pointer" onClick={() => setStartTime(new Date(startTime.getTime() + oneDateTime))}></ArrowForwardIosIcon>
          </div>
          <div className="flex flex-row gap-4 items-center">
            <ArrowBackIosIcon className="cursor-pointer" onClick={() => setEndTime(new Date(endTime.getTime() - oneDateTime))}></ArrowBackIosIcon>
            <DatePicker key={"endTime" + endTime.getTime()} onChange={(e) => setEndTime(e.toDate())} format="DD.MM.YYYY" defaultValue={dayjs(endTime)} slotProps={{textField: {size: 'small'}}} formatDensity="dense" label={t("label.end")}/>
            <ArrowForwardIosIcon onClick={() => setEndTime(new Date(endTime.getTime() + oneDateTime))} className="cursor-pointer"></ArrowForwardIosIcon>
          </div>
        </div>
        <div className="flex flex-row md:mr-6 gap-4 items-center">
          <When condition={isAdmin}>
            <Button variant="outlined" size="small" onClick={() => setSensorConfigDefaultValues(sensorConfig)}>
              <p>
                <ModeEditIcon></ModeEditIcon> {t("button.edit.datastream")}
              </p>
            </Button>
          </When>
          <When condition={isAdmin}>
            <ConfirmModal
              size="small"
              variant="outlined"
              onConfirm={() => axios.delete(`/api/sensor-configs/${sensorConfigId}`).then(() => dispatch(getAllSensorConfigsByDevice(deviceId)))}
              text={t("confirm.delete.datastream")}
              buttonText={<> <DeleteIcon></DeleteIcon> {t("button.delete.datastream")} </>}
            />
          </When>
        </div>
      </div>
      <SensorConfigEditModal
        defaultValues={sensorConfigDefaultValues}
        deviceId={sensorConfig?.deviceId}
        onSubmitValue={(x) => {
          dispatch(saveSensorConfig(x));
          toast.success(t("toast.datastream.saved"));
        }}
        onModalClose={() => setSensorConfigDefaultValues(null)}
      />
      <When condition={sensorDataLoading}>
        <div className="flex justify-center mb-20 mt-20">
          <div className="w-12 h-12 rounded-2xl animate-spin border-y border-solid border-yellow-500"></div>
        </div>
      </When>
      <When condition={!sensorDataLoading}>
        <If condition={finalData?.length < 2}>
          <Then>
            <div className="my-5 bg-gray-100 rounded-xl p-5 text-center">
              {t("label.no.data")}
            </div>
          </Then>
          <Else>
            <Line options={options2} data={data2} />
          </Else>
        </If>
      </When>
    </div>
  );
}

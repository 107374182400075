import React, {useCallback, useState} from 'react';
import {Button} from "@mui/material";
import OperatorTextSubmitModal from "app/pages/device-operator-controlls/operator-text-submit-modal";
import axios from "axios";
import {toast} from "react-toastify";
import {useParams} from "react-router-dom";
import {useDropzone} from "react-dropzone";
import {When} from "react-if";
import {useTranslation} from "react-i18next";

export const DeviceOperatorControlsPage = () => {
  const {id} = useParams<'id'>();
  const { t, i18n } = useTranslation();

  const [filesUploading, setFilesUploading] = useState(0);
  const onDrop = useCallback(acceptedFiles => {
    setFilesUploading(acceptedFiles.length)
    acceptedFiles.forEach((file) => {
      handleImageUpload(file);
    });
  }, [])
  const {getRootProps, getInputProps, open} = useDropzone({noClick: true, onDrop})

  const [modalOpen, setModalOpen] = useState(false);
  const [type, setType] = useState("problem");

  const handleImageUpload = (file) => {
    var formData = new FormData();
    formData.append("file", file);
    axios.post('api/public/images/device-picture/' +id +'/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(result => {
      setFilesUploading(filesUploading - 1)
      toast.success("Image uploaded successfully");
    }, error => {
      console.log(error);
      toast.error("Error uploading image " + error?.response?.data?.detail)
    });
  };

  const buttonText = "text-3xl p-6";
  return (
    <div className="flex flex-col gap-10 w-full max-w-4xl m-auto mt-12">

      <When condition={filesUploading > 0}>
        <div className="flex justify-center mb-20 mt-20">
          <div
            className="w-12 h-12 rounded-2xl animate-spin border-y border-solid border-yellow-500"></div>
        </div>
        <div className="flex justify-center">
          Laadin {filesUploading} pilti
        </div>
      </When>
      <Button variant="contained" onClick={() => {
        setType("problem")
        setModalOpen(true);
      }}>
        <p className={buttonText}>
          {t("button.register.problem.or.maintenance")}
        </p>
      </Button>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <Button variant="contained" className="w-full text-8xl	" onClick={open}>
          <p className={buttonText}>
            {t("button.upload.image")}
          </p>
        </Button>
      </div>
      <OperatorTextSubmitModal type={type}
                               modalOpen={modalOpen}
                               closeModal={() => setModalOpen(false)}/>
    </div>
  );
};

export default DeviceOperatorControlsPage;


import 'react-toastify/dist/ReactToastify.css';
import './app.scss';
import { Chart, registerables } from 'chart.js';

import React, {useEffect, useState} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {toast, ToastContainer} from 'react-toastify';
import 'chartjs-adapter-moment';

import {useAppDispatch} from 'app/config/store';
import {getSession} from 'app/reducers/authentication';
import Frame from 'app/shared/layout/header/frame';
import PrivateRoute from 'app/shared/auth/private-route';
import {AUTHORITIES} from 'app/config/constants';
import {createTheme, ThemeProvider} from "@mui/material";
import 'react-chat-elements/dist/main.css'
import Loadable from 'react-loadable';
import PageNotFoundRedirect from "app/shared/util/page-not-found-redirect";
import Landing from "app/pages/landing/landing";
import DevicesPage from "app/pages/devices/devices";
import DevicesDetailsPage from "app/pages/device-details/device-details";
import ProductionRoomsPage from "app/pages/production-rooms/production-rooms";
import DeviceOperatorControlsPage from "app/pages/device-operator-controlls/device-operator-controlls";
import ProductionRoomsDetails from "app/pages/production-rooms/production-room-details";
import {green, grey, indigo, teal} from "@mui/material/colors";
import {gray} from "next/dist/lib/picocolors";
import blueGrey from "@mui/material/colors/blueGrey";

const baseHref = '/';
const loading = <div>loading ...</div>;

const InfoRoutes = Loadable({
  loader: () => import(/* webpackChunkName: "about" */ './routes/about'),
  loading: () => loading,
});

const ContentRoutes = Loadable({
  loader: () => import(/* webpackChunkName: "content" */ './routes/content'),
  loading: () => loading,
});

const AdminRoutes = Loadable({
  loader: () => import(/* webpackChunkName: "administration" */ './routes/admin'),
  loading: () => loading,
});

export const App = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getSession());
  });

  const theme = createTheme({
    palette: {
      primary: {
        main: '#0F0F0F'
      },
      secondary: {
        main: '#F50057'
      },
      info: {
        main: '#6C63FF'
      }
    },
  });

  Chart.register(...registerables);


  return (
    <BrowserRouter basename={baseHref}>

      <ThemeProvider theme={theme}>
        <Frame>
          <ToastContainer position={toast.POSITION.TOP_LEFT} className="toastify-container"
                          toastClassName="toastify-toast"/>
          <Routes>
            <Route index element={<Landing/>}/>
            <Route path="dashboard" element={<ProductionRoomsPage/>}/>
            <Route path="factory-rooms/:id/devices" element={<DevicesPage/>}/>
            <Route path="factory-rooms/:id" element={<ProductionRoomsDetails/>}/>
            <Route path="factory-rooms" element={<ProductionRoomsPage/>}/>
            <Route path="device-operator-controls/:id" element={<DeviceOperatorControlsPage/>}/>
            <Route path="device-details/:id" element={<DevicesDetailsPage/>}/>
            <Route path="info/*" element={<InfoRoutes/>}/>
            <Route path="404" element={<PageNotFoundRedirect/>}/>
            <Route
              path="admin/*"
              element={
                <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN]}>
                  <AdminRoutes/>
                </PrivateRoute>
              }
            />
            <Route path='*' element={<ContentRoutes/>}/>
          </Routes>
        </Frame>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;

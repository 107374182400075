import React from 'react';
import {useForm} from 'react-hook-form';
import {Box, Button, Modal, TextField} from "@mui/material";
import {style} from "app/shared/util/modal-style";
import {useAppDispatch, useAppSelector} from "app/config/store";
import {Close} from "@mui/icons-material";
import {When} from "react-if";
import {saveProductionDeviceProblem} from "app/reducers/production-device-problem.reducer";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

export type ReadingTypes = 'maintenance' | 'problem'

interface OperatorTextSubmitModalParams {
  type: string
  modalOpen: boolean
  closeModal: () => void
}

const OperatorTextSubmitModal = ({type, modalOpen, closeModal}: OperatorTextSubmitModalParams) => {
  const { t, i18n } = useTranslation();

  const dispatch = useAppDispatch();
  const {id} = useParams<'id'>();
  const {
    register,
    getValues,
    reset,
    formState: {errors},
  } = useForm({mode: 'onTouched'});

  const onSubmit = (event) => {
    event.preventDefault()
    const values = getValues();
    dispatch(saveProductionDeviceProblem({problem: values, code: id}));
    toast.success(t("email.form.success"));
    closeModal();
  };

  return (
    <>
      <Modal
        disableEnforceFocus
        open={modalOpen}
        onClose={() => {
          closeModal()
          reset()
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <form onSubmit={onSubmit} className="space-y-2">
            <div className="flex justify-between items-center mb-2">
              <div>
              </div>
              <h3 className="font-semibold text-lg">
                <When condition={type === "problem"}>
                  Kirjelda probleemi/hooldust
                </When>
                <When condition={type === "maintenance"}>
                  Kirjelda hooldust
                </When>
              </h3>
              <div className="cursor-pointer" onClick={() => closeModal()}>
                <Close></Close>
              </div>
            </div>
            <TextField
              required
              id="description"
              name="description"
              color="secondary"
              label={t("label.description")}
              multiline={true}
              rows={4}
              type="text"
              fullWidth
              {...register('description')}
              error={!!errors.description}
            />
            <p>
              {errors.newPassword?.message}
            </p>
            <Button
              className="w-full"
              variant="contained"
              type="submit">
              <p className={"text-xl p-2"}>
                Salvesta
              </p>
            </Button>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default OperatorTextSubmitModal;

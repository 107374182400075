import React, {useEffect} from 'react';
import {DeviceBlock} from "app/pages/devices/device-block";
import {useAppDispatch, useAppSelector} from "app/config/store";
import {useParams} from "react-router-dom";
import {getProductionDevice} from "app/reducers/production-device.reducer";

export const DevicesDetailsPage = () => {
  const dispatch = useAppDispatch();
  const {id} = useParams<'id'>();
  const entity = useAppSelector(state => state.productionDevices.entity);

  useEffect(() => {
    dispatch(getProductionDevice(id));
  }, []);

  return (
    <div className="flex items-center flex-col max-w-8xl m-auto w-full">
      <DeviceBlock detailed={true} index={null} device={entity}/>
    </div>
  );
};

export default DevicesDetailsPage;


import React from 'react'
import {FeatureHighlight} from './feature-highlight'
import {useTranslation} from "react-i18next";

export default function FeaturesBlocks() {
  const {t, i18n} = useTranslation();

  return (
    <section className="relative">

      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          <div className="max-w-3xl mx-auto text-center pb-6 md:pb-6">
            <h2 className="text-2xl md:text-4xl font-bold mt-12">
              {t('landingpage.block2.title')}
            </h2>
          </div>
          <div
            className="mx-auto grid gap-6 md:grid-cols-2 lg:grid-cols-3 items-start md:max-w-2xl lg:max-w-none">
            <FeatureHighlight title={t('landingpage.block2.card1.title')}
                              image={'/images/udraw/letters.svg'}
                              description={t('landingpage.block2.card1.description')}/>
            <FeatureHighlight title={t('landingpage.block2.card2.title')}
                              image={'/images/udraw/photo.svg'}
                              description={t('landingpage.block2.card2.description')}/>
            <FeatureHighlight title={t('landingpage.block2.card3.title')}
                              image={'/images/udraw/dashboard.svg'}
                              description={t('landingpage.block2.card3.description')}/>
            <FeatureHighlight title={t('landingpage.block2.card4.title')}
                              image={'/images/udraw/search.svg'}
                              description={t('landingpage.block2.card4.description')}/>
            <FeatureHighlight title={t('landingpage.block2.card5.title')}
                              image={'/images/udraw/code-block.svg'}
                              description={t('landingpage.block2.card5.description')}/>
            <FeatureHighlight title={t('landingpage.block2.card6.title')}
                              image={'/images/udraw/building-blocks.svg'}
                              description={t('landingpage.block2.card6.description')}/>
          </div>
        </div>
      </div>
    </section>
  )
}

import React, {useEffect, useState} from 'react';
import {deleteProductionRoom, getAllProductionsRooms, saveProductionRoom} from "app/reducers/production-rooms.reducer";
import {useAppDispatch, useAppSelector} from "app/config/store";
import {Button} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {CrudEditModal} from "app/pages/production-rooms/crud-edit-modal";
import {ConfirmModal} from "app/pages/production-rooms/confirm-modal";
import {hasAnyAuthority} from "app/shared/auth/private-route";
import {AUTHORITIES} from "app/config/constants";
import {When} from "react-if";
import {useTranslation} from "react-i18next";

export const ProductionRoomsPage = () => {
  const {t, i18n} = useTranslation();

  const dispatch = useAppDispatch();
  const [defaultFormValues, setDefaultFormValues] = useState(null);
  const navigate = useNavigate();

  const rooms = useAppSelector(state => state.productionRooms.entities);
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  const canEdit = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN, AUTHORITIES.USER]));

  useEffect(() => {
    dispatch(getAllProductionsRooms());
  }, []);

  const wrapper = "bg-gray-100 rounded-3xl p-5 transition hover:bg-gray-200 border-2 border-gray-200 transition ease-in-out delay-50 duration-300 ";
  return (
    <div className="max-w-6xl m-auto">
      <div className="my-5 flex flex-col md:flex-row items-center justify-between">
        <div className="basis-full"></div>
        <h2 className="text-4xl font-semibold my-5 basis-full text-center">
          {t("title.rooms")}
        </h2>
        <div className="basis-full flex justify-end">
          <When condition={isAdmin}>
            <Button variant="contained" color="secondary" onClick={() => {
              setDefaultFormValues({});
            }}>
              {t("create.new.room.button")}
            </Button>
          </When>
        </div>
      </div>

      <CrudEditModal defaultValues={defaultFormValues}
                     onSubmitValue={(x) => dispatch(saveProductionRoom(x))}
                     title={defaultFormValues?.id ? <>{t("edit.room.data")}</> : <>{t("create.new.room")}</>}
                     onModalClose={() => setDefaultFormValues(null)}

      />
      <When condition={!rooms?.length}>
        <div className="my-5 bg-gray-100 rounded-xl p-5 text-center">
          {t("label.no.data")}
        </div>
      </When>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        <When condition={rooms?.length}>
          <img src='/images/udraw/building.svg' className="w-full" alt=""/>
        </When>
        {rooms?.slice().sort((a, b) => a.orderNumber - b.orderNumber).map((room, i) => (
          <div key={i} className={wrapper}>
            <div className="flex flex-col w-full">
              <div className="font-semibold text-2xl mb-3">
                {room?.name}
              </div>
              <div className={"flex flex-col md:flex-row gap-3 mt-2 w-full"}>
                <div className="flex flex-col gap-3 w-full text-sm">
                  <div>
                    <p className="font-semibold inline">{t("room.block.nr.of.devices")}</p>  {room.deviceCount}
                  </div>
                  <div>
                    <p className="font-semibold inline">{t("room.block.temp")}</p> -- °C
                  </div>
                  <div className="break-after-all">
                    <p className="font-semibold inline">{t("room.block.description")}</p> {room.description || '--'}
                  </div>
                </div>
                <div className="flex flex-col gap-3">
                  <Button variant="contained"
                          color="primary"
                          size={"small"}
                          className="w-full" onClick={() => {
                    navigate(`/factory-rooms/${room.id}/devices`);
                  }}>{t("button.devices")}</Button>
                  <When condition={canEdit}>
                    <Button variant="outlined" size={"small"}
                            className="w-full" onClick={() => {
                      setDefaultFormValues(room)
                    }}>{t("room.block.button.edit")}</Button>
                    <ConfirmModal onConfirm={() => dispatch(deleteProductionRoom(room.id))}
                                  size={"small"}
                                  text={t("room.block.button.delete.confirmation", {name: room.name})}
                                  buttonText={t("button.delete")}/>
                  </When>

                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductionRoomsPage;


import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  loginModalRequested: null,
  registerModalRequested: null,
};

export type LoginModalState = Readonly<typeof initialState>;

export const LoginModalSlice = createSlice({
  name: 'loginModal',
  initialState: initialState as LoginModalState,
  reducers: {
    resetModals(state) {
      state.loginModalRequested = null;
      state.registerModalRequested = null;
    },
    openLoginModal(state) {
      state.registerModalRequested = null;
      state.loginModalRequested = new Date();
    },
    openRegisterModal(state) {
      state.loginModalRequested = null;
      state.registerModalRequested = new Date();
    },
  },

});

export const { resetModals, openLoginModal, openRegisterModal } = LoginModalSlice.actions;

// Reducer
export default LoginModalSlice.reducer;

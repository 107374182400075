import DeviceInfo from "app/pages/devices/components/device-info";
import React, {useState} from "react";
import {Button} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {Else, If, Then, When} from "react-if";
import {QrCodeModal} from "app/shared/components/qr-code-modal";
import {useAppDispatch, useAppSelector} from "app/config/store";
import {DeviceEditModal} from "app/pages/production-rooms/device-edit-modal";
import {deleteProductionDevice, saveProductionDevice} from "app/reducers/production-device.reducer";
import {SensorConfigEditModal} from "app/pages/production-rooms/sensor-config-edit-modal";
import {saveSensorConfig} from "app/reducers/sensor-config.reducer";
import {ConfirmModal} from "app/pages/production-rooms/confirm-modal";
import {hasAnyAuthority} from "app/shared/auth/private-route";
import {AUTHORITIES} from "app/config/constants";
import {useTranslation} from "react-i18next";

interface DeviceBlockParams {
  detailed: boolean;
  device: any;
  index: number;
}

export function DeviceBlock({device, detailed, index}: DeviceBlockParams) {
  const host = window.location.protocol + "//" + window.location.host;
  const {t, i18n} = useTranslation();

  const [defaultFormValues, setDefaultFormValues] = useState(null);
  const [sensorConfigDefaultValues, setSensorConfigDefaultValues] = useState(null);
  const dispatch = useAppDispatch();
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  const canEdit = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN, AUTHORITIES.USER]));

  const navigate = useNavigate();
  return <div className="w-full">
    <If condition={detailed}>
      <Then>
        <div className={"flex flex-col items-center w-full"}>
          <div className={"font-semibold text-3xl mb-3"}>
            {device?.name}
          </div>
          <div className={"flex flex-col md:flex-row gap-3 mt-2 relative"}>
            <Button variant="outlined" onClick={() => {
              navigate('/device-operator-controls/' + device.code)
            }}>
              {t("button.for.operator")}
            </Button>
            <QrCodeModal name={t("button.generate.qr.code")} text={host + "/device-operator-controls/" + device?.code}/>
            <When condition={canEdit}>
              <Button variant="outlined" onClick={() => {
                setDefaultFormValues(device);
              }}>
                {t("button.configure")}
              </Button>
            </When>
            <When condition={isAdmin}>
              <Button
                variant="outlined"
                onClick={() => {
                  setSensorConfigDefaultValues({});
                }}
                type="submit">
                {t("button.new.data.stream")}
              </Button>
              <ConfirmModal onConfirm={() => {
                dispatch(deleteProductionDevice({...device}));
                navigate('/dashboard');
              }}
                            variant="outlined"
                            text={t("button.delete.device.confirmation")}
                            buttonText={t("button.delete")}/>
            </When>
            <SensorConfigEditModal defaultValues={sensorConfigDefaultValues}
                                   deviceId={device?.id}
                                   onSubmitValue={(x) => dispatch(saveSensorConfig(x))}
                                   onModalClose={() => setSensorConfigDefaultValues(null)}/>
            <DeviceEditModal defaultValues={defaultFormValues}
                             onSubmitValue={(x) => dispatch(saveProductionDevice(x))}
                             onModalClose={() => setDefaultFormValues(null)}/>
          </div>
          <DeviceInfo detailed={detailed} device={device}/>
        </div>
      </Then>
      <Else>
        <div className={"font-semibold text-xl mb-3"}>
          {device?.name}
        </div>
        <div className={"flex flex-col w-full"}>
          <div className={"flex flex-col mt-2 relative"}>
            <div className="grid grid-cols-1 gap-4">
              <Button variant="contained"
                      size="small"
                      onClick={() => {
                        navigate('/device-details/' + device?.id)
                      }}>
                {t("button.view.details")}
              </Button>
              <QrCodeModal name={t("button.generate.qr.code")}
                           buttonSize="small"
                           text={host + "/device-operator-controls/" + device?.code}/>
            </div>
          </div>
        </div>
      </Else>
    </If>
  </div>
}

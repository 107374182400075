import React, {useEffect, useState} from 'react';
import {Else, If, Then, When} from "react-if";
import {Paper, TableCell, TableContainer} from '@mui/material';
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import {useAppDispatch, useAppSelector} from "app/config/store";
import {useParams} from "react-router-dom";
import {
  deleteProductionDeviceProblem,
  getAllProductionsDeviceProblems
} from "app/reducers/production-device-problem.reducer";
import axios from "axios";
import {DataChart} from "app/pages/devices/components/data-chart";
import {getAllSensorConfigsByDevice} from "app/reducers/sensor-config.reducer";
import {ConfirmModal} from "app/pages/production-rooms/confirm-modal";
import {hasAnyAuthority} from "app/shared/auth/private-route";
import {AUTHORITIES} from "app/config/constants";
import {useTranslation} from "react-i18next";

interface DeviceInfoParams {
  detailed: boolean;
  device: any;
}

export const DeviceInfo = ({detailed, device}: DeviceInfoParams) => {
  const {id} = useParams<'id'>();
  const dispatch = useAppDispatch();
  const [pictures, setPictures] = useState([]);
  const deviceProblems = useAppSelector(state => state.productionDevicesProblems.entities);
  const sensorConfigs = useAppSelector(state => state.sensorConfigs.entities);
  const sensorConfigsLoading = useAppSelector(state => state.sensorConfigs.loading);
  const {t, i18n} = useTranslation();

  function loadPictures() {
    axios.get(`api/images/device/${id}`).then((response) => {
      setPictures(response.data)
    })
  }

  useEffect(
    () => {
      dispatch(getAllProductionsDeviceProblems(id))
      dispatch(getAllSensorConfigsByDevice(id as any))
      loadPictures();
    }, [id]
  )

  const options = {
    legend: {position: 'top'},
    title: "",
    colors: ['#F50057', '#6C63FF', '#00BFA6', '#F9A826', '#00B0FF'],
    chartArea: {'width': '90%', height: '90%'},
    hAxis: {
      format: 'HH:mm',
    }
  };

  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  const canEdit = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN, AUTHORITIES.USER]));

  return (
    <If condition={sensorConfigsLoading}>
      <Then>
        <div className="flex justify-center mb-20 mt-20">
          <div
            className="w-12 h-12 rounded-2xl animate-spin border-y border-solid border-yellow-500"></div>
        </div>
      </Then>
      <Else>
        <div className=" flex flex-col items-center m-auto gap-8 w-full">
          {sensorConfigs?.slice().sort((a, b) => a.orderNumber - b.orderNumber).map((sensorConfig, i) => (
            <DataChart key={sensorConfig.id} deviceId={id} options={options} sensorConfig={sensorConfig}/>
          ))}
          <div className="m-auto flex flex-col m-auto gap-8" style={{width: "100%"}}>
            <When condition={detailed}>
              <When condition={deviceProblems.length}>
                <div className="text-2xl font-semibold"> {t("title.problems.and.maintentences")}</div>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>{t("table.header.time")}</TableCell>
                        <TableCell align="left">{t("table.header.description")}</TableCell>
                        <When condition={isAdmin}>
                          <TableCell align="left">{t("table.header.agent")}</TableCell>
                        </When>
                        <When condition={canEdit}>
                          <TableCell align="left"></TableCell>
                        </When>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {deviceProblems.map((row, i) => (
                        <TableRow
                          key={i}
                          sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                          <TableCell component="th" scope="row">
                            {new Date(row?.createdDate).toLocaleString()}
                          </TableCell>
                          <TableCell align="left" width="500px">
                            <p className="whitespace-pre-wrap">
                              {row.description}
                            </p>
                          </TableCell>
                          <When condition={isAdmin}>
                            <TableCell align="left" width="400px">
                              {row.userAgent}
                            </TableCell>
                          </When>
                          <When condition={canEdit}>
                            <TableCell align="left">

                              <ConfirmModal
                                onConfirm={() => dispatch(deleteProductionDeviceProblem({
                                  problemId: row.id,
                                  deviceId: id
                                }))}
                                variant="text"
                                text={t("delete.text.confirm")}
                                description={
                                  <div>
                                    <p className="whitespace-pre-wrap">
                                      {row.description}
                                    </p>
                                  </div>
                                }
                                buttonText={t("button.delete")}/>
                            </TableCell>
                          </When>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </When>
              <When condition={pictures.length}>
                <div className="text-2xl font-semibold"> {t("title.pictures")}</div>
                <div className="flex flex-col md:flex-row gap-4">
                  {pictures.map((picture, i) => (
                    <div key={i} className="cursor-pointer">
                      <img src={picture.url} alt="seadme pilt" width={300}/>
                      <When condition={canEdit}>
                        <ConfirmModal
                          onConfirm={() => axios.delete(`api/images/device-picture/${picture.id}`)
                            .then((response) => {
                              loadPictures();
                            })}
                          variant="text"
                          text={t("text.delete.image.confirm")}
                          description={
                            <div className="items-center w-full flex justify-center">
                              <img src={picture.url} alt="seadme pilt" width={300}/>
                            </div>
                          }
                          buttonText={t("button.delete")}/>
                      </When>
                    </div>
                  ))}
                </div>
              </When>
            </When>
          </div>
        </div>
      </Else>
    </If>
  );
};

export default DeviceInfo;


import React, {useEffect, useState} from "react";
import {Box, Button, Modal, TextField} from "@mui/material";
import {style} from "app/shared/util/modal-style";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";

export const CrudEditModal = ({
                                defaultValues,
                                onSubmitValue,
                                onModalClose,
                                title
                              }: { defaultValues: any, onSubmitValue: (any) => void, onModalClose: () => void, title: any }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const handleItemSave = (x) => onSubmitValue(x)
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (!modalOpen) {
      onModalClose()
    }
  }, [modalOpen])

  const {
    register,
    getValues,
    reset,
    formState: {errors},
  } = useForm({mode: 'onTouched'});

  useEffect(() => {
    reset(defaultValues)
    if (defaultValues) {
      setModalOpen(true)
    }
  }, [defaultValues])

  const onSubmit = (event) => {
    event.preventDefault()
    handleItemSave(getValues());
    setModalOpen(false)
  };


  return <Modal
    disableEnforceFocus
    open={modalOpen}
    onClose={() => {
      setModalOpen(false)
      reset();
    }}>
    <Box sx={style}>
      <form onSubmit={onSubmit} className="space-y-2">
        <div className="flex justify-between items-center mb-2">
          <div>
          </div>
          <h3 className="font-semibold text-lg">
            {title}
          </h3>
        </div>
        <TextField
          required
          id="name"
          name="name"
          color="secondary"
          label={t("form.label.name")}
          type="text"
          fullWidth
          {...register('name')}
        />
        <TextField
          id="description"
          name="description"
          color="secondary"
          label={t("form.label.description")}
          type="text"
          fullWidth
          {...register('description')}
        />
        <TextField
          id="orderNumber"
          name="orderNumber"
          color="secondary"
          label={t("form.label.order.number")}
          type="number"
          fullWidth
          {...register('orderNumber')}
        />
        <Button
          className="w-full"
          variant="contained"
          type="submit">
          {t("button.save")}
        </Button>
      </form>
    </Box>
  </Modal>;
}

import React from "react"
import Hero from "./components/hero";
import Features from "./components/features";
import FeaturesBlocks from "./components/features-blocks";
import {Contact} from "app/shared/components/contact";
import { useTranslation } from 'react-i18next';

export default function Landing() {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Hero/>
      <Features/>
      <FeaturesBlocks/>
      <div className="py-16">
        <Contact/>
      </div>
    </>
  )
}

import React, {useEffect, useState} from "react";
import {Box, Button, Checkbox, FormControlLabel, Modal, TextField} from "@mui/material";
import {style} from "app/shared/util/modal-style";
import {useForm} from "react-hook-form";
import {useAppDispatch} from "app/config/store";
import {getAllProductionsRooms} from "app/reducers/production-rooms.reducer";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import {useTranslation} from "react-i18next";

export const DeviceEditModal = ({
                                  defaultValues,
                                  onSubmitValue,
                                  onModalClose
                                }: { defaultValues: any, onSubmitValue: (any) => void, onModalClose: () => void }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const handleItemSave = (x) => onSubmitValue(x)
  const dispatch = useAppDispatch();
  const {t, i18n} = useTranslation();

  useEffect(() => {
    dispatch(getAllProductionsRooms());
  }, []);

  useEffect(() => {
    if (!modalOpen) {
      onModalClose()
    }
  }, [modalOpen])

  const {
    register,
    getValues,
    reset,
    formState: {errors},
  } = useForm({mode: 'onTouched'});

  useEffect(() => {
    reset(defaultValues)
    if (defaultValues) {
      setModalOpen(true)
    }
  }, [defaultValues])

  const onSubmit = (event) => {
    event.preventDefault()
    handleItemSave(getValues());
    setModalOpen(false)
  };


  return <Modal
    disableEnforceFocus
    open={modalOpen}
    onClose={() => {
      setModalOpen(false)
      reset();
    }}>
    <Box sx={style}>
      <form onSubmit={onSubmit} className="space-y-2">
        <div className="flex justify-between items-center mb-2">
          <div>
          </div>
          <h3 className="font-semibold text-lg">
            <>{t("title.edit.device.data")}</>
          </h3>
        </div>
        <TextField
          required
          id="name"
          name="name"
          color="secondary"
          label={t("label.name")}
          type="text"
          fullWidth
          {...register('name')}
        />
        <TextField
          id="description"
          name="description"
          color="secondary"
          label={t("label.description")}
          type="text"
          fullWidth
          {...register('description')}
        />
        <TextField
          id="orderNumber"
          name="orderNumber"
          color="secondary"
          label={t("label.order.nr")}
          type="number"
          fullWidth
          {...register('orderNumber')}
        />
        <TextField
          id="notificationEmail"
          name="notificationEmail"
          color="secondary"
          label={t("label.notification.email")}
          type="email"
          fullWidth
          {...register('notificationEmail')}
        />
        <FormControl sx={{width: "100%"}}>
          <InputLabel id="demo-multiple-checkbox-label">{t("label.lang.of.notification.email")}</InputLabel>
          <Select
            key={`langkey-device`}
            labelId="demo-multiple-checkbox-label"
            defaultValue={defaultValues?.langKey}
            id="demo-multiple-checkbox"
            {...register('langKey')}
            input={<OutlinedInput label={t("label.notification.email")}/>}>
            <MenuItem value="en">
              <ListItemText primary="en"/>
            </MenuItem>
            <MenuItem value="et">
              <ListItemText primary="et"/>
            </MenuItem>
          </Select>
        </FormControl>
        <FormControlLabel
          control={<Checkbox defaultChecked={defaultValues?.imageNotifications} {...register('imageNotifications')} />}
          label={t("label.notify.about.images")}/>
        <FormControlLabel
          control={<Checkbox defaultChecked={defaultValues?.textNotifications}  {...register('textNotifications')} />}
          label={t("label.notify.about.text")}/>
        <Button
          className="w-full"
          variant="contained"
          type="submit">
          {t("button.save")}
        </Button>
      </form>
    </Box>
  </Modal>;
}

import React, {useState} from "react";
import {Box, Button, Modal} from "@mui/material";
import {style} from "app/shared/util/modal-style";
import QRCode from "react-qr-code";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {useTranslation} from "react-i18next";
import {OverridableStringUnion} from "@mui/types";
import {ButtonPropsSizeOverrides} from "@mui/material/Button/Button";

interface QrCodeModalParams {
  text: string;
  name: string;
  buttonSize?:  OverridableStringUnion<'small' | 'medium' | 'large', ButtonPropsSizeOverrides>;
}

export function QrCodeModal({text, buttonSize, name}: QrCodeModalParams) {
  const [modalOpen, setModalOpen] = useState(false);
  const { t, i18n } = useTranslation();

  const generatePD = () => {
    const pdf = new jsPDF();
    const qrCodeContainer = document.querySelector('.HpQrcode');
    // @ts-ignore
    html2canvas(qrCodeContainer).then((canvas) => {
      const imageData = canvas.toDataURL('image/png');
      // Calculate the center position for the QR code in the PDF
      const centerX = pdf.internal.pageSize.width / 2;
      // Set the desired width and height for the QR code in the PDF
      const qrCodeWidth = 100; // Adjust as needed
      const qrCodeHeight = 100; // Adjust as needed
      pdf.addImage(imageData, 'PNG', centerX - qrCodeWidth / 2, 15, qrCodeWidth, qrCodeHeight);
      pdf.save('qrcode.pdf');
    });
  };

  return <>
    <Button variant="outlined" size={buttonSize || "medium"} onClick={() => setModalOpen(true)}>
      {name}
    </Button>
    <Modal
      disableEnforceFocus
      open={modalOpen}
      onClose={() => {
        setModalOpen(false)
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style}>
        <div style={{height: "auto", margin: "0 auto", width: "100%"}} className="HpQrcode">
          <QRCode
            size={256}
            style={{height: "auto", maxWidth: "100%", width: "100%"}}
            value={text}
            viewBox={`0 0 256 256`}
          />
        </div>
        <div className="mt-4 flex justify-between">
          <Button variant="outlined" onClick={() => generatePD()}>
            {t("button.download")}
          </Button>
          <Button variant="outlined" onClick={() => setModalOpen(false)}>
            {t("button.close")}
          </Button>
        </div>
      </Box>
    </Modal>
  </>;
}

import axios from 'axios';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {serializeAxiosError} from "app/reducers/reducer.utils";

const initialState = {
  loading: false,
  errorMessage: null,
  successMessage: null,
  updateSuccess: false,
  updateFailure: false,
  entities: [],
};

export type ProductionDeviceState = Readonly<typeof initialState>;

export const getAllProductionsDevices = createAsyncThunk('production-devices/get', async (id: string) => axios.get<any>('api/production-devices/room/' + id));

export const getProductionDevice = createAsyncThunk('production-devices/get-by-id', async (id: string, thunkAPI) => axios.get<any>('api/production-devices/' + id));

export const saveProductionDevice = createAsyncThunk(
  'production-devices/save',
  async (payload: any, thunkAPI) => {
    const result = await axios.post<any>('api/production-devices', payload)
    thunkAPI.dispatch(getAllProductionsDevices(payload.roomId));
    return result;
  }
);

export const deleteProductionDevice = createAsyncThunk(
  'production-devices/delete',
  async (payload: { id: string, roomId: string }, thunkAPI) => {
    console.log(payload);
    const requestUrl = `api/production-devices/${payload.id}`;
    const result = await axios.delete<any>(requestUrl);
    if (payload.roomId) {
      thunkAPI.dispatch(getAllProductionsDevices(payload.roomId));
    }
    return result;
  },
  {serializeError: serializeAxiosError}
);

const ProductionDevicesSlice = createSlice({
  name: 'productionDevices',
  initialState: initialState as ProductionDeviceState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAllProductionsDevices.rejected, (state, action) => ({
        ...initialState,
        errorMessage: action.error.message,
      }))
      .addCase(getAllProductionsDevices.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        loginError: false,
        showModalLogin: false,
        loginSuccess: true,
        entities: action.payload.data || [],
      }))
      .addCase(getAllProductionsDevices.pending, state => {
        state.loading = true;
      })
      .addCase(getProductionDevice.rejected, (state, action) => ({
        ...initialState,
        errorMessage: action.error.message,
      }))
      .addCase(getProductionDevice.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        loginError: false,
        showModalLogin: false,
        loginSuccess: true,
        entity: action.payload.data || {},
      }))
      .addCase(getProductionDevice.pending, state => {
        state.loading = true;
      })
      .addCase(saveProductionDevice.rejected, (state, action) => ({
        ...initialState,
        errorMessage: action.error.message,
      }))
      .addCase(saveProductionDevice.fulfilled, state => ({
        ...state,
        loading: false,
        loginError: false,
        showModalLogin: false,
        loginSuccess: true,
      }))
      .addCase(saveProductionDevice.pending, state => {
        state.loading = true;
      })
  }
});

export default ProductionDevicesSlice.reducer;

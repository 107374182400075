import React from "react";
import {Button, TextField} from "@mui/material";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import axios from "axios";
import {useTranslation} from "react-i18next";

export function Contact() {
  const { t, i18n } = useTranslation();
  const {
    register,
    getValues,
    reset,
    formState: {},
  } = useForm({mode: 'onTouched'});

  const submit = (e: any) => {
    e.preventDefault();
    const values = getValues();
    axios.post("/api/public/notification", values)
      .then((response) => {
        toast.success(t("email.form.success"));
        reset();
      }, (error) => {
        toast.error(t("email.form.error"));
      });
  }

  return <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
    <h1 className="text-3xl font-bold mb-2 max-w-4xl">{t("email.form.title")}</h1>
    <form onSubmit={submit}>
      <div className="mb-4">
        <TextField
          required
          id="name"
          {...register('name')}
          name="name"
          color="secondary"
          type="text"
          label={t("email.form.name")}
          fullWidth
        />
      </div>
      <div className="mb-4">
        <TextField
          required
          id="email"
          name="email"
          {...register('email')}
          color="secondary"
          type="email"
          label={t("email.form.email")}
          fullWidth
        />
      </div>
      <div className="mb-4">
        <TextField
          id="standard-multiline-flexible"
          className="w-full"
          color="secondary"
          required
          label={t("email.form.message")}
          multiline
          {...register('message')}
          minRows={4}
          maxRows={4}
        />
      </div>
      <div>
        <Button variant="contained" color="secondary" type="submit">{t("email.form.button")}</Button>
      </div>
    </form>
  </div>;
}

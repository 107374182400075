import axios from 'axios';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import {IUser} from 'app/shared/model/user.model';
import {serializeAxiosError} from 'app/reducers/reducer.utils';

const initialState = {
  loading: false,
  errorMessage: null,
  roomAccesses: [] as ReadonlyArray<any>,
  updating: false,
  updateSuccess: false,
  totalItems: 0,
};

export const getAllRoomAccesses = createAsyncThunk('roomAccess/fetchAll', async () => {
  return axios.get<any[]>("/api/admin/room-accesses");
});

export const createRoomAccess = createAsyncThunk(
  'roomAccess/create',
  async (roomAccess: any, thunkAPI) => {
    const result = await axios.post<IUser>("/api/admin/room-accesses", roomAccess);
    thunkAPI.dispatch(getAllRoomAccesses());
    return result;
  },
  {serializeError: serializeAxiosError}
);

export const removeRoomAccess = createAsyncThunk(
  'roomAccess/delete',
  async (id: string, thunkAPI) => {
    const result = await axios.delete<IUser>("/api/admin/room-accesses/" + id);
    thunkAPI.dispatch(getAllRoomAccesses());
    return result;
  },
  {serializeError: serializeAxiosError}
);

export type RoomAccessManagementState = Readonly<typeof initialState>;

export const RoomAccessManagementSlice = createSlice({
  name: 'roomAccessManagement',
  initialState: initialState as RoomAccessManagementState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAllRoomAccesses.pending, (state, action) => ({
        ...state,
        loading: true,
      }))
      .addCase(getAllRoomAccesses.rejected, (state, action) => ({
        ...initialState,
        errorMessage: action.error.message,
      }))
      .addCase(getAllRoomAccesses.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        roomAccesses: action.payload.data || [],
      }))
      .addCase(createRoomAccess.fulfilled, (state, action) => {
        state.updating = false;
        state.updateSuccess = true;
      })
      .addCase(removeRoomAccess.fulfilled, (state, action) => {
        state.updating = false;
        state.updateSuccess = true;
      })
  },
});

export const {reset} = RoomAccessManagementSlice.actions;

export default RoomAccessManagementSlice.reducer;

import React, {useState} from "react";
import {Box, Button, Modal} from "@mui/material";
import {style} from "app/shared/util/modal-style";
import {When} from "react-if";
import ClearIcon from "@mui/icons-material/Clear";
import {useTranslation} from "react-i18next";

export const ConfirmModal = ({
                               onConfirm,
                               clearIcon,
                               text,
                               description,
                               buttonText,
                               size,
                               variant
                             }: { onConfirm: () => void, text: any, clearIcon?: boolean, description?: any, buttonText: any, size?: 'small' | 'medium' | 'large', variant?: 'text' | 'outlined' | 'contained' },
) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { t, i18n } = useTranslation();

  return <>
    <When condition={!clearIcon}>
      <Button variant={variant || "outlined"} size={size || "medium"} onClick={() => {
        setModalOpen(true)
      }}>{buttonText}</Button>
    </When>
    <When condition={clearIcon}>
      <ClearIcon className="cursor-pointer" onClick={() => {
        setModalOpen(true)
      }}></ClearIcon>
    </When>

    <Modal
      disableEnforceFocus
      open={modalOpen}
      onClose={() => {
        setModalOpen(false)
      }}>
      <Box sx={style}>
        <div className="flex justify-between items-center mb-2">
          <div>
          </div>
          <div className="w-full">
            <h3 className="font-semibold text-lg mb-3">
              {text}
            </h3>
            <When condition={description}>
              {description}
            </When>
          </div>
        </div>
        <div className="flex flex-row gap-3 mt-6">
          <Button
            className="w-full"
            variant="contained"
            onClick={() => {
              onConfirm()
              setModalOpen(false)
            }}>
            {t("button.yes")}
          </Button>
          <Button
            className="w-full"
            variant="outlined"
            onClick={() => setModalOpen(false)}>
            {t("button.no")}
          </Button>
        </div>
      </Box>
    </Modal>
  </>
}

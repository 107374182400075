import axios from 'axios';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {serializeAxiosError} from "app/reducers/reducer.utils";

const initialState = {
  loading: false,
  errorMessage: null,
  successMessage: null,
  updateSuccess: false,
  updateFailure: false,
  entities: [],
};

export type ProductionRoomState = Readonly<typeof initialState>;

export const getAllProductionsRooms = createAsyncThunk('production-rooms/get', async () => axios.get<any>('api/production-rooms'));

export const getProductionRoom = createAsyncThunk('production-rooms/get-by-id', async (id: string, thunkAPI) => axios.get<any>('api/production-rooms/' + id));

export const saveProductionRoom = createAsyncThunk(
  'production-rooms/save',
  async (payload: any, thunkAPI) => {
    const result = await axios.post<any>('api/production-rooms', payload)
    thunkAPI.dispatch(getAllProductionsRooms());
    if (payload.id) {
      thunkAPI.dispatch(getProductionRoom(payload.id));
    }
    return result;
  }
);

export const deleteProductionRoom = createAsyncThunk(
  'production-rooms/delete',
  async (id: string, thunkAPI) => {
    const requestUrl = `api/production-rooms/${id}`;
    const result = await axios.delete<any>(requestUrl);
    thunkAPI.dispatch(getAllProductionsRooms());
    return result;
  },
  {serializeError: serializeAxiosError}
);

const ProductionRoomsSlice = createSlice({
  name: 'productionRooms',
  initialState: initialState as ProductionRoomState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAllProductionsRooms.rejected, (state, action) => ({
        ...initialState,
        errorMessage: action.error.message,
      }))
      .addCase(getAllProductionsRooms.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        loginError: false,
        showModalLogin: false,
        loginSuccess: true,
        entities: action.payload.data || [],
      }))
      .addCase(getAllProductionsRooms.pending, state => {
        state.loading = true;
      })
      .addCase(getProductionRoom.rejected, (state, action) => ({
        ...initialState,
        errorMessage: action.error.message,
      }))
      .addCase(getProductionRoom.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        loginError: false,
        showModalLogin: false,
        loginSuccess: true,
        entity: action.payload.data || {},
      }))
      .addCase(getProductionRoom.pending, state => {
        state.loading = true;
      })
      .addCase(saveProductionRoom.rejected, (state, action) => ({
        ...initialState,
        errorMessage: action.error.message,
      }))
      .addCase(saveProductionRoom.fulfilled, state => ({
        ...state,
        loading: false,
        loginError: false,
        showModalLogin: false,
        loginSuccess: true,
      }))
      .addCase(saveProductionRoom.pending, state => {
        state.loading = true;
      })
  }
});

export default ProductionRoomsSlice.reducer;

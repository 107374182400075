import React from "react";

interface FeatureHighlightParams {
  title: string
  image: string
  description: string
}

export function FeatureHighlight({title, image, description}: FeatureHighlightParams) {
  return <div className="relative flex justify-between flex-col p-6 rounded-xl h-full bg-gray-100" style={{minHeight: "260px"}}>
    <div className="mb-4">
      <h4 className="text-xl font-semibold mb-1">{title}</h4>
      <p className="text-gray-600 z-30">{description}</p>
    </div>
    <img src={image} className="max-h-32" alt=""/>
  </div>;
}

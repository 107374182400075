import axios from 'axios';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {serializeAxiosError} from "app/reducers/reducer.utils";

const initialState = {
  loading: false,
  errorMessage: null,
  successMessage: null,
  updateSuccess: false,
  updateFailure: false,
  entities: [],
};

export type SensorConfigState = Readonly<typeof initialState>;

export const getAllSensorConfigsByDevice = createAsyncThunk('sensor-configs/get',
  async (deviceId: number) => axios.get<any>('api/sensor-configs/device/' + deviceId));

export const saveSensorConfig = createAsyncThunk(
  'sensor-configs/save',
  async (payload: any, thunkAPI) => {
    console.log(payload)
    const result = await axios.post<any>('api/sensor-configs', payload)
    thunkAPI.dispatch(getAllSensorConfigsByDevice(payload.deviceId));
    return result;
  }
);

export const deleteProductionDevice = createAsyncThunk(
  'sensor-configs/delete',
  async (payload: {id: string, deviceId: number}, thunkAPI) => {
    const requestUrl = `api/sensor-configs/${payload.id}`;
    const result = await axios.delete<any>(requestUrl);
    thunkAPI.dispatch(getAllSensorConfigsByDevice(payload.deviceId));
    return result;
  },
  {serializeError: serializeAxiosError}
);

const SensorConfigSlice = createSlice({
  name: 'sensorConfigs',
  initialState: initialState as SensorConfigState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAllSensorConfigsByDevice.rejected, (state, action) => ({
        ...initialState,
        errorMessage: action.error.message,
      }))
      .addCase(getAllSensorConfigsByDevice.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        loginError: false,
        showModalLogin: false,
        loginSuccess: true,
        entities: action.payload.data || [],
      }))
      .addCase(getAllSensorConfigsByDevice.pending, state => {
        state.loading = true;
      })
      .addCase(saveSensorConfig.rejected, (state, action) => ({
        ...initialState,
        errorMessage: action.error.message,
      }))
      .addCase(saveSensorConfig.fulfilled, state => ({
        ...state,
        loading: false,
        loginError: false,
        showModalLogin: false,
        loginSuccess: true,
      }))
      .addCase(saveSensorConfig.pending, state => {
        state.loading = true;
      })
  }
});

export default SensorConfigSlice.reducer;

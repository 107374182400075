import {createSlice} from '@reduxjs/toolkit';

export const BackgroundSlice = createSlice({
  name: 'backgroundColor',
  initialState: {
    backGroundColor: '',
  },
  reducers: {
    setColor(state, action) {
      state.backGroundColor += action.payload
    },
  },
})
export const { setColor } = BackgroundSlice.actions;

export default BackgroundSlice.reducer;

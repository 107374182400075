import './header.scss';

import React, {Suspense, useEffect} from 'react';
import {useAppDispatch} from "app/config/store";
import {getSession} from "app/reducers/authentication";
import {Footer} from "app/shared/layout/footer/footer";

export interface IHeaderProps {
  children: any;
  noPadding?: boolean;
}

const Header = React.lazy(() => import('app/shared/layout/header/header'));

const Frame = (props: IHeaderProps) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getSession());
  }, []);

  return (
    <>
      <Suspense fallback={""}>
        <Header></Header>
      </Suspense>
      <div className={"p-5 md:px-10"}>
        <div className={props.noPadding ? "frame-container" : "frame-container"}
             style={{margin: "0 auto"}}>
          {props.children}
        </div>
      </div>
      <Footer/>
    </>
  )
};

export default Frame;

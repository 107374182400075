import React, {useEffect, useState} from 'react';
import {
  deleteProductionRoom,
  getProductionRoom,
  saveProductionRoom
} from "app/reducers/production-rooms.reducer";
import {useAppDispatch, useAppSelector} from "app/config/store";
import {Button} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {CrudEditModal} from "app/pages/production-rooms/crud-edit-modal";
import {useTranslation} from "react-i18next";

export const ProductionRoomsDetails = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [defaultFormValues, setDefaultFormValues] = useState(null);
  const {id} = useParams<'id'>();
  const entity = useAppSelector(state => state.productionRooms.entity);

  useEffect(() => {
    dispatch(getProductionRoom(id));
  }, []);

  return (
    <div className="max-w-4xl m-auto">
      <CrudEditModal defaultValues={defaultFormValues}
                     onSubmitValue={(x) => dispatch(saveProductionRoom(x))}
                     title={<>{t("edit.room.data")}</>}
                     onModalClose={() => setDefaultFormValues(null)}/>
      <div className="flex justify-between items-center mb-2 border-2 rounded-xl my-5 py-5 px-3">
        <div className="text-left w-full p-5">
          <h3 className="font-semibold text-lg">
            {entity?.name}
          </h3>
          <p>
            {entity?.description}
          </p>
        </div>
        <div className="flex flex-col gap-3">
          <div>
            <Button variant="outlined" className="w-full" onClick={() => {
              setDefaultFormValues(entity)
            }}>{t("button.change")}</Button>
          </div>
          <div>
            <Button variant="outlined" className="w-full" onClick={() => {
              dispatch(deleteProductionRoom(entity?.id));
              navigate('/production-rooms');
            }}>{t("button.delete")}</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductionRoomsDetails;


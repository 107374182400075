export const style = {
  position: 'absolute' as const,
  top: '50%',
  overflowY: 'auto',
  left: '50%',
  width: "35rem",
  marginLeft: ".75rem",
  marginRight: ".75rem",
  margin: "0 auto",
  maxWidth: "calc(100vw - 1.5rem)",
  maxHeight: "calc(100vh - 1.5rem)",
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '0px',
  borderRadius: "1rem",
  boxShadow: 24,
  p: 4,
};

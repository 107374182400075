'use client'
import React, {useEffect, useRef, useState} from 'react'
import {Transition} from '@headlessui/react'
import {useTranslation} from "react-i18next";
import {FeatureHighlightBig} from "app/pages/landing/components/feature-highlight-big";

export default function Features() {

  const [tab, setTab] = useState<number>(1)
  const {t, i18n} = useTranslation();

  const tabs = useRef<HTMLDivElement>(null)

  const heightFix = () => {
    if (tabs.current && tabs.current.parentElement) tabs.current.parentElement.style.height = `${tabs.current.clientHeight}px`
  }

  useEffect(() => {
    heightFix()
  }, [])

  return (
    <section className="relative max-w" style={{maxWidth: "100vw"}}>

      {/* Section background (needs .relative class on parent and next sibling elements) */}
      <div className="absolute left-0 right-0 m-auto w-px p-px h-20 bg-gray-200 transform -translate-y-1/2"></div>

      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-12 md:pt-20">
          <div className="md:grid md:grid-cols-12 md:gap-6">
            <div
              className="md:w-full mx-auto col-span-12 lg:col-span-6 flex flex-col pb-6">
              <div className="md:pr-4 lg:pr-12 xl:pr-16 mb-8 text-left">
                <h3 className="text-2xl md:text-3xl mb-3 font-bold"> {t('landingpage.block1.title')}</h3>
                <p className="text-xl text-gray-600">{t('landingpage.block1.description')}</p>
              </div>
              <img src='/images/udraw/computer.svg'  alt=""/>
            </div>
            <div className="max-w-xl md:max-w-none md:w-full mx-auto sm:col-span-12 lg:col-span-6"
                 data-aos="fade-right">
              <div className="mb-8 md:mb-0 flex flex-col gap-4">
                <FeatureHighlightBig title={t('landingpage.block1.card1.title')}
                                     image={'/images/udraw/surveillance.svg'}
                                     description={t('landingpage.block1.card1.description')}/>
                <FeatureHighlightBig title={t('landingpage.block1.card2.title')}
                                     image={'/images/udraw/alert.svg'}
                                     description={t('landingpage.block1.card2.description')}/>
                <FeatureHighlightBig title={t('landingpage.block1.card3.title')}
                                     image={'/images/udraw/build.svg'}
                                     description={t('landingpage.block1.card3.description')}/>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  )
}

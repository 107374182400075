import React from 'react';
import setupAxiosInterceptors from "./app/config/axios-interceptor";
import {bindActionCreators} from "redux";
import getStore from './app/config/store';
import {clearAuthentication} from 'app/reducers/authentication';
import {Provider} from 'react-redux';
import AppComponent from './app/app';
import ReactDOM from "react-dom/client";
import {LocalizationProvider} from "@mui/x-date-pickers";
import './app/i18n/config';
import 'moment/locale/et'
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";

const store = getStore();
const actions = bindActionCreators({clearAuthentication}, store.dispatch);
setupAxiosInterceptors(() => actions.clearAuthentication('login.error.unauthorized'));

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterDayjs}>

    <Provider store={store}>
        <AppComponent/>
      </Provider>
    </LocalizationProvider>
  </React.StrictMode>);


import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from "./en.json";
import et from "./et.json";

const resources = {
  en: {
    translation: en
  },
  et: {
    translation: et
  }
};


i18next.use(initReactI18next).init({
  fallbackLng: localStorage.getItem('i18nextLng'),
  debug: true,
  resources: resources
});

import axios from 'axios';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {serializeAxiosError} from "app/reducers/reducer.utils";

const initialState = {
  loading: false,
  errorMessage: null,
  successMessage: null,
  updateSuccess: false,
  updateFailure: false,
  entities: [],
};

export type ProductionDeviceProblemState = Readonly<typeof initialState>;

export const getAllProductionsDeviceProblems = createAsyncThunk('production-devices-problems/get', async (id: string) => axios.get<any>('api/device-problems/device/' + id));

export const saveProductionDeviceProblem = createAsyncThunk(
  'production-device-problem/save',
  async (payload: any, thunkAPI) => {
    return await axios.post<any>('api/public/device-problem/' + payload.code, payload.problem);
  }
);

export const deleteProductionDeviceProblem = createAsyncThunk(
  'production-devices/delete',
  async ({problemId, deviceId}: any, thunkAPI) => {
    const requestUrl = `api/device-problems/${problemId}`;
    const result = await axios.delete<any>(requestUrl);
    thunkAPI.dispatch(getAllProductionsDeviceProblems(deviceId));
    return result;
  },
  {serializeError: serializeAxiosError}
);

const ProductionDevicesProblemsSlice = createSlice({
  name: 'productionDevicesProblems',
  initialState: initialState as ProductionDeviceProblemState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAllProductionsDeviceProblems.rejected, (state, action) => ({
        ...initialState,
        errorMessage: action.error.message,
      }))
      .addCase(getAllProductionsDeviceProblems.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        loginError: false,
        showModalLogin: false,
        loginSuccess: true,
        entities: action.payload.data || [],
      }))
      .addCase(getAllProductionsDeviceProblems.pending, state => {
        state.loading = true;
      })
  }
});

export default ProductionDevicesProblemsSlice.reducer;

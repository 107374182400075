import React from 'react'
import {useTranslation} from "react-i18next";

export default function Hero() {
  const {t, i18n} = useTranslation();

  return (
    <div>
      <section className="relative">
        <div className="max-w-6xl mx-auto px-4 sm:px-6">
          <div className="pt-18 pb-12 pt-10 md:pt-20 md:pb-20">
            <div className="text-center pb-16 md:pb-28">
              <h1 className="text-3xl sm:text-4xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4"
                  data-aos="zoom-y-out">{t('landingpage.title.part1')}<span
                className="bg-clip-text text-transparent bg-secondary"> {t('landingpage.title.part2')}</span>
              </h1>
              <div className="max-w-3xl mx-auto">
                <p className="text-xl text-gray-600 mb-8" data-aos="zoom-y-out" data-aos-delay="150">
                  {t('landingpage.title.description')}
                </p>
                <img src='/images/udraw/factory.svg' className="w-full max-h-80" alt=""/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

  )
}

import React, {useEffect, useState} from "react";
import {Box, Button, Modal, TextField} from "@mui/material";
import {style} from "app/shared/util/modal-style";
import {useForm} from "react-hook-form";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {useTranslation} from "react-i18next";

export const SensorConfigEditModal = ({
                                defaultValues,
                                onSubmitValue,
                                deviceId,
                                onModalClose
                              }: { defaultValues: any, deviceId: number, onSubmitValue: (any) => void, onModalClose: () => void }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const handleItemSave = (x) => onSubmitValue({...x, deviceId})

  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (!modalOpen) {
      onModalClose()
    }
  }, [modalOpen])

  const {
    register,
    getValues,
    reset,
    formState: {errors},
  } = useForm({mode: 'onTouched'});

  useEffect(() => {
    reset(defaultValues)
    if (defaultValues) {
      setModalOpen(true)
    }
  }, [defaultValues])

  const onSubmit = (event) => {
    event.preventDefault()
    handleItemSave(getValues());
    setModalOpen(false)
  };

  const types = [
    "SteppedAreaChart",
    "LineChart"
  ]
  return <Modal
    disableEnforceFocus
    open={modalOpen}
    onClose={() => {
      setModalOpen(false)
      reset();
    }}>
    <Box sx={style}>
      <form onSubmit={onSubmit} className="space-y-2">
        <div className="flex justify-between items-center mb-2">
          <div>
          </div>
          <h3 className="font-semibold text-lg">
            {t("title.create.datastream")}
          </h3>
        </div>
        <Select
          key={`type${defaultValues?.id}`}
          labelId="type-label"
          fullWidth
          label={t("label.type")}
          id="demo-multiple-checkbox-type"
          defaultValue={defaultValues?.type}
          {...register('type')}
          input={<OutlinedInput label={t("label.type")}/>}>
          {types.map((type) => (
            <MenuItem key={type} value={type}>
              <p>
                {type}
              </p>
            </MenuItem>
          ))}
        </Select>
        <TextField
          id="fields"
          name="Fields"
          color="secondary"
          label={t("label.fields")}
          type="text"
          fullWidth
          {...register('fields')}
        />
        <TextField
          id="topic"
          name="Topic"
          color="secondary"
          label={t("label.topic")}
          type="text"
          fullWidth
          {...register('topic')}
        />
        <TextField
          id="orderNumber"
          name="orderNumber"
          color="secondary"
          label={t("label.order.nr")}
          type="number"
          fullWidth
          {...register('orderNumber')}
        />
        <TextField
          id="graphConfig"
          name="Graph config"
          color="secondary"
          multiline
          minRows={8}
          label={t("label.graph.config")}
          type="text"
          fullWidth
          {...register('graphConfig')}
        />
        <TextField
          id="staticFields"
          name="Static fields"
          color="secondary"
          multiline
          rows={3}
          label={t("label.static.fields")}
          type="text"
          helperText={"JSON ex: {\"average\": 200}"}
          fullWidth
          {...register('staticFields')}
        />
        <Button
          className="w-full"
          variant="contained"
          type="submit">
          {t("button.save")}
        </Button>
      </form>
    </Box>
  </Modal>;
}

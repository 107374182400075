import React, {useEffect, useState} from 'react';
import {DeviceBlock} from "app/pages/devices/device-block";
import {Button} from "@mui/material";
import {CrudEditModal} from "app/pages/production-rooms/crud-edit-modal";
import {useAppDispatch, useAppSelector} from "app/config/store";
import {useNavigate, useParams} from "react-router-dom";
import {getAllProductionsDevices, saveProductionDevice} from "app/reducers/production-device.reducer";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {Else, If, Then, When} from "react-if";
import {hasAnyAuthority} from "app/shared/auth/private-route";
import {AUTHORITIES} from "app/config/constants";
import {useTranslation} from "react-i18next";

export const DevicesPage = () => {
  const dispatch = useAppDispatch();
  const {id} = useParams<'id'>();
  const {t, i18n} = useTranslation();

  const [defaultFormValues, setDefaultFormValues] = useState(null);
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));

  const navigate = useNavigate();
  useEffect(
    () => {
      dispatch(getAllProductionsDevices(id));
    }, []
  )
  const devices = useAppSelector(state => state.productionDevices.entities);
  const devicesLoading = useAppSelector(state => state.productionDevices.loading);

  const wrapper = "bg-gray-100 rounded-3xl p-5 transition hover:bg-gray-200 border-2 border-gray-200 transition ease-in-out delay-50 duration-300 ";
  return (
    <div className="max-w-6xl m-auto mt-5">
      <div className="my-5">
        <div className="my-5 flex flex-col md:flex-row items-center justify-between basis-full">
          <div className="basis-full">
            <Button onClick={() => navigate("/dashboard")}>
              <ArrowBackIcon></ArrowBackIcon>
              {t("title.rooms")}
            </Button>
          </div>
          <h2 className="text-4xl font-semibold my-5 grow text-center basis-full">
            {t("title.devices")}
          </h2>
          <div className="flex basis-full justify-end">
            <When condition={isAdmin}>
              <Button variant="contained" color="secondary" onClick={() => {
                setDefaultFormValues({roomId: Number(id)});
              }}>
                {t("create.new.device.button")}
              </Button>
            </When>
          </div>
        </div>
      </div>
      <CrudEditModal defaultValues={defaultFormValues}
                     onSubmitValue={(x) => dispatch(saveProductionDevice(x))}
                     title={defaultFormValues?.id ? <>Muuda seadme andmeid</> : <>Loo uus seade</>}
                     onModalClose={() => setDefaultFormValues(null)}/>
      <When condition={!devices?.length}>
        <div className="my-5 bg-gray-100 rounded-xl p-5 text-center">
          {t("label.no.devices")}
        </div>
      </When>

      <If condition={devicesLoading}>
        <Then>
          <div className="flex justify-center mb-20 mt-20">
            <div
              className="w-12 h-12 rounded-2xl animate-spin border-y border-solid border-yellow-500"></div>
          </div>
        </Then>
        <Else>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {devices?.slice().sort((a, b) => a.orderNumber - b.orderNumber).map((device, i) => (
              <div className={wrapper}>
                <DeviceBlock detailed={false} index={i + 1 as any} device={device}/>
              </div>
            ))}
          </div>
        </Else>
      </If>

    </div>
  );
};

export default DevicesPage;


import axios from 'axios';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  errorMessage: null,
  successMessage: null,
  updateSuccess: false,
  updateFailure: false,
  entities: [],
};

export type SensorDataState = Readonly<typeof initialState>;

export const getAllSensorDataByConfig = createAsyncThunk('newSensors/get', async () => axios.get<any>('api/sensor-configs/admin/new-sensors'));

const NewSensorConfigSlice = createSlice({
  name: 'newSensor',
  initialState: initialState as SensorDataState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAllSensorDataByConfig.rejected, (state, action) => ({
        ...initialState,
        errorMessage: action.error.message,
      }))
      .addCase(getAllSensorDataByConfig.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        loginError: false,
        showModalLogin: false,
        loginSuccess: true,
        entities: action.payload.data || [],
      }))
      .addCase(getAllSensorDataByConfig.pending, state => {
        state.loading = true;
      })
  }
});

export default NewSensorConfigSlice.reducer;

import {ReducersMapObject} from '@reduxjs/toolkit';

import authentication from './authentication';
import background from './background';

import userManagement from "app/pages/administration/user-management/user-management.reducer";
import productionRooms from "./production-rooms.reducer";
import sensorConfigs from "./sensor-config.reducer";
import newSensor from "./new-sensor.reducer";
import roomAccess from "./room-access.reducer";
import productionDevices from "./production-device.reducer";
import productionDevicesProblems from "./production-device-problem.reducer";
import register from "app/pages/account/register/register.reducer";
import activate from "app/pages/account/activate/activate.reducer";
import password from "app/pages/account/password/password.reducer";
import settings from "app/pages/account/settings/settings.reducer";
import passwordReset from "app/pages/account/password-reset/password-reset.reducer";
import loginModal from "app/shared/components/login/login-modal.reducer";

const rootReducer: ReducersMapObject = {
  authentication,
  background,
  sensorConfigs,
  loginModal,
  userManagement,
  register,
  activate,
  passwordReset,
  password,
  roomAccess,
  settings,
  productionRooms,
  productionDevices,
  newSensor,
  productionDevicesProblems,
};

export default rootReducer;
